import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import ReactMarkdown from 'react-markdown';
import { v4 as uuidv4 } from 'uuid';
import { signInWithPopup, setPersistence, browserLocalPersistence, onAuthStateChanged } from 'firebase/auth';
import { auth, googleProvider, db } from './services/firebaseConfig';
import { doc, setDoc, updateDoc, increment, serverTimestamp } from 'firebase/firestore';
import './App.css';

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loginError, setLoginError] = useState(null);
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');
  const [selectedImage, setSelectedImage] = useState(null);
  const [imagePreviewUrl, setImagePreviewUrl] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [language, setLanguage] = useState('ko'); // Default language set to Korean
  const [cameraPermissionGranted, setCameraPermissionGranted] = useState(false);
  const chatBoxRef = useRef(null);
  const [hasUserInteracted, setHasUserInteracted] = useState(false);

  useEffect(() => {
    setPersistence(auth, browserLocalPersistence)
      .then(() => {
        console.log('Persistence set to local.');
      })
      .catch((error) => {
        console.error('Error setting persistence:', error);
      });

    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        console.log('User is logged in:', user);
        setIsLoggedIn(true);
      } else {
        console.log('No user is logged in.');
        setIsLoggedIn(false);
      }
    });

    return () => unsubscribe();
  }, []);

  const handleGoogleSignIn = async () => {
    try {
      const result = await signInWithPopup(auth, googleProvider);
      console.log('User signed in:', result.user);
      setIsLoggedIn(true);
      setLoginError(null);

      const userDoc = doc(db, 'users', result.user.uid);
      await setDoc(userDoc, {
        lastLogin: serverTimestamp(),
        chatInitiations: 0,
        cameraUploads: 0,
        tokenUsage: 0,
      }, { merge: true });
    } catch (error) {
      console.error('Error during sign-in:', error.message);
      setLoginError('Failed to sign in with Google');
    }
  };

  const [sessionId] = useState(() => {
    let storedSessionId = sessionStorage.getItem('sessionId');
    if (!storedSessionId) {
      storedSessionId = uuidv4();
      sessionStorage.setItem('sessionId', storedSessionId);
    }
    return storedSessionId;
  });

  const buildTimestamp = process.env.REACT_APP_BUILD_TIMESTAMP || 'Unknown';

  const getGreetingMessage = (lang) => {
    return lang === 'en'
      ? 'Welcome to SkinMatch AI! How can I assist you with your skin concerns today?'
      : 'SkinMatch AI에 오신 것을 환영합니다! 피부 고민에 대해 어떻게 도와드릴까요?';
  };

  useEffect(() => {
    if (isLoggedIn) {
      setMessages([{ sender: 'bot', text: getGreetingMessage(language) }]);
    }
  }, [language, isLoggedIn]);

  useEffect(() => {
    if (chatBoxRef.current) {
      chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight;
    }
  }, [messages, isLoading]);

  const handleLogin = (e) => {
    e.preventDefault();
    if (email === 'test@example.com' && password === 'password') {
      setIsLoggedIn(true);
      setLoginError(null);
    } else {
      setLoginError('Invalid email or password');
    }
  };

  const requestCameraPermission = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true });
      if (stream) {
        setCameraPermissionGranted(true);
        stream.getTracks().forEach((track) => track.stop());
      }
    } catch (error) {
      console.error('Camera permission denied:', error);
      setCameraPermissionGranted(false);
    }
  };

  const handleImageChange = async (e) => {
    if (!cameraPermissionGranted) {
      await requestCameraPermission();
    }

    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      setSelectedImage(file);

      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreviewUrl(reader.result);
      };
      reader.readAsDataURL(file);

      if (auth.currentUser) {
        await logUserAction(auth.currentUser.uid, 'cameraUploads');
      }
    }
  };

  const logUserAction = async (userId, actionType) => {
    try {
      const userDoc = doc(db, 'users', userId);
      await updateDoc(userDoc, {
        [actionType]: increment(1),
      });
    } catch (error) {
      console.error('Error updating user actions:', error);
    }
  };

  
  const processAnalysisResult = (result, language) => {
    const issues = [];
    const descriptions = {
      pores_left_cheek: (value) => value === 1 ? (language === 'en' ? 'Enlarged pores on the left cheek detected.' : '왼쪽 뺨에 확장된 모공이 감지되었습니다.') : null,
      nasolabial_fold: (value) => value === 1 ? (language === 'en' ? 'Nasolabial folds are present.' : '팔자 주름이 있습니다.') : null,
      eye_pouch: (value) => value === 1 ? (language === 'en' ? 'Eye bags detected.' : '눈 밑 지방이 감지되었습니다.') : null,
      forehead_wrinkle: (value) => value === 1 ? (language === 'en' ? 'Forehead wrinkles are visible.' : '이마 주름이 보입니다.') : null,
      skin_spot: (value) => value === 1 ? (language === 'en' ? 'Skin spots detected.' : '피부 반점이 감지되었습니다.') : null,
      acne: (value) => value === 1 ? (language === 'en' ? 'Acne present.' : '여드름이 있습니다.') : null,
      pores_forehead: (value) => value === 1 ? (language === 'en' ? 'Enlarged pores on the forehead detected.' : '이마에 확장된 모공이 감지되었습니다.') : null,
      pores_jaw: (value) => value === 1 ? (language === 'en' ? 'Enlarged pores on the jawline detected.' : '턱선에 확장된 모공이 감지되었습니다.') : null,
      left_eyelids: (value) => {
        const types = {
          0: language === 'en' ? 'Single eyelids on the left eye.' : '왼쪽 눈은 홑꺼풀입니다.',
          1: language === 'en' ? 'Parallel double eyelids on the left eye.' : '왼쪽 눈은 평행형 쌍꺼풀입니다.',
          2: language === 'en' ? 'Scalloped double eyelids on the left eye.' : '왼쪽 눈은 물결형 쌍꺼풀입니다.',
        };
        return types[value] || null;
      },
      eye_finelines: (value) => value === 1 ? (language === 'en' ? 'Fine lines around the eyes detected.' : '눈 주위에 잔주름이 감지되었습니다.') : null,
      dark_circle: (value) => value === 1 ? (language === 'en' ? 'Dark circles under the eyes detected.' : '눈 밑 다크서클이 감지되었습니다.') : null,
      crows_feet: (value) => value === 1 ? (language === 'en' ? "Crow's feet wrinkles detected." : '눈가에 까마귀 발 주름이 감지되었습니다.') : null,
      pores_right_cheek: (value) => value === 1 ? (language === 'en' ? 'Enlarged pores on the right cheek detected.' : '오른쪽 뺨에 확장된 모공이 감지되었습니다.') : null,
      blackhead: (value) => value === 1 ? (language === 'en' ? 'Blackheads detected.' : '블랙헤드가 감지되었습니다.') : null,
      glabella_wrinkle: (value) => value === 1 ? (language === 'en' ? 'Wrinkles between the eyebrows detected.' : '미간에 주름이 감지되었습니다.') : null,
      mole: (value) => value === 1 ? (language === 'en' ? 'Moles present.' : '점이 있습니다.') : null,
      right_eyelids: (value) => {
        const types = {
          0: language === 'en' ? 'Single eyelids on the right eye.' : '오른쪽 눈은 홑꺼풀입니다.',
          1: language === 'en' ? 'Parallel double eyelids on the right eye.' : '오른쪽 눈은 평행형 쌍꺼풀입니다.',
          2: language === 'en' ? 'Scalloped double eyelids on the right eye.' : '오른쪽 눈은 물결형 쌍꺼풀입니다.',
        };
        return types[value] || null;
      },
      skin_type: ({ skin_type, details }) => {
        const types = {
          0: language === 'en' ? 'Oily skin type detected.' : '지성 피부로 감지되었습니다.',
          1: language === 'en' ? 'Dry skin type detected.' : '건성 피부로 감지되었습니다.',
          2: language === 'en' ? 'Neutral skin type detected.' : '중성 피부로 감지되었습니다.',
          3: language === 'en' ? 'Combination skin type detected.' : '복합성 피부로 감지되었습니다.',
        };
        const skinTypeDescription = types[skin_type] || null;

        // Append detail information if available
        if (skinTypeDescription && details && details.length > 0) {
          const detailDescriptions = details.map(detail => {
            const areas = {
              0: language === 'en' ? 'Forehead' : '이마',
              1: language === 'en' ? 'Left cheek' : '왼쪽 뺨',
              2: language === 'en' ? 'Right cheek' : '오른쪽 뺨',
              3: language === 'en' ? 'Chin' : '턱',
            };
            const area = areas[detail.area] || (language === 'en' ? 'Unknown area' : '알 수 없는 부위');
            const oilLevel = detail.oil_level !== undefined ? detail.oil_level : (language === 'en' ? 'Unknown oil level' : '알 수 없는 유분 수준');
            return `${area}: Oil level ${oilLevel}`;
          });
          return `${skinTypeDescription} ${detailDescriptions.join(', ')}.`;
        }

        return skinTypeDescription;
      },
    };

    for (const [key, value] of Object.entries(result)) {
      const description = descriptions[key]?.(value);
      if (description) {
        issues.push(description);
      }
    }

    return issues.length
      ? (language === 'en'
        ? `The skin analysis indicates the following issues: ${issues.join(', ')}. Please provide skincare advice and product recommendations.`
        : `피부 분석 결과 다음과 같은 문제가 감지되었습니다: ${issues.join(', ')}. 피부 관리 팁과 제품 추천을 제공해 주세요.`)
      : (language === 'en'
        ? 'No significant skin issues detected. Please provide general skincare advice.'
        : '특별한 피부 문제가 감지되지 않았습니다. 일반적인 피부 관리 팁을 제공해 주세요.');
  };

  const handleSend = async () => {
    if (!input.trim() && !selectedImage) return;

    setHasUserInteracted(true); // Mark that the user has interacted

    if (selectedImage) {
      setMessages((prevMessages) => [
        ...prevMessages,
        { sender: 'user', text: '', imageUrl: imagePreviewUrl }
      ]);
    } else {
      setMessages((prevMessages) => [...prevMessages, { sender: 'user', text: input }]);
    }

    const userInput = input;
    setInput('');
    setIsLoading(true);

    try {
      if (selectedImage) {
        const formData = new FormData();
        formData.append('image', selectedImage);
        formData.append('sessionId', sessionId);
        formData.append('language', language);

        const response = await axios.post('/api/getImageAnalysisByAILabTools', formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });

        if (auth.currentUser) {
          await logUserAction(auth.currentUser.uid, 'tokenUsage');
        }

        if (response && response.data) {
          const analysisResult = response.data;
          console.log("Analysis Result:", JSON.stringify(analysisResult, null, 2));

          const analysisSummary = processAnalysisResult(analysisResult.result, language);

          const chatResponse = await axios.post('/api/getOpenAIResponse', {
            prompt: analysisSummary,
            sessionId,
            messages,
            language,
          });

          if (chatResponse && chatResponse.data) {
            setMessages((prevMessages) => [
              ...prevMessages,
              { sender: 'bot', text: chatResponse.data },
            ]);
          } else {
            throw new Error('Invalid response from ChatGPT');
          }
        } else {
          throw new Error('Invalid response from AILabTools');
        }
      } else {
        const response = await axios.post('/api/getOpenAIResponse', {
          prompt: language === 'en'
            ? `You are a skincare AI assistant. ${userInput}`
            : `당신은 피부 관리 AI 어시스턴트입니다. ${userInput}`,
          sessionId,
          messages,
          language,
        });

        if (response && response.data) {
          setMessages((prevMessages) => [
            ...prevMessages,
            { sender: 'bot', text: response.data },
          ]);

          if (auth.currentUser) {
            await logUserAction(auth.currentUser.uid, 'chatInitiations');
          }
        } else {
          throw new Error('Invalid response from ChatGPT');
        }
      }
    } catch (error) {
      console.error('Error:', error);
      setMessages((prevMessages) => [
        ...prevMessages,
        { sender: 'bot', text: language === 'en' ? 'Sorry, there was an error processing your request. Please try again.' : '죄송합니다. 요청을 처리하는 중에 오류가 발생했습니다. 다시 시도해 주세요.' },
      ]);
    } finally {
      setIsLoading(false);
      setSelectedImage(null);
      setImagePreviewUrl(null);
    }
  };

  const handleLanguageChange = (e) => {
    setLanguage(e.target.value);
  };

  const extractRecommendedProducts = async (interactionContext, messages) => {
    try {
      const response = await axios.post('/api/getOpenAIResponse', {
        prompt: `Analyze the conversation and extract specific cosmetic products, product types, manufacturers, or brands. Return them as a space-separated list of groups, each group separated by a comma.`,
        sessionId,
        messages, // Pass the full message history to the backend
        mode: 'extractProducts', // Indicate the type of request
        language,
      });

      if (response && response.data) {
        console.log('Extracted Product Groups:', response.data);
        const productGroups = response.data.trim().split(',');
        return productGroups.map(group => group.trim()); // Return product groups as an array
      } else {
        throw new Error('Failed to extract recommended products.');
      }
    } catch (error) {
      console.error('Error extracting products:', error);
      return ['general cosmetic products']; // Provide a fallback product group
    }
  };

  const handleSearch = async () => {
    if (!hasUserInteracted) {
      setMessages((prevMessages) => [
        ...prevMessages,
        { sender: 'bot', text: language === 'en' ? 'Please enter a message before using the search feature.' : '검색 기능을 사용하기 전에 메시지를 입력해 주세요.' }
      ]);
      return;
    }
  
    const lastInteractions = messages.slice(-5).map((msg) => `${msg.sender === 'user' ? 'User' : 'Bot'}: ${msg.text}`).join('\n');
  
    const productGroups = await extractRecommendedProducts(lastInteractions, messages);
  
    for (const group of productGroups) {
      console.log(`Searching for group: "${group}"`);
      try {
        const domains = [
          'ulta.com',
          'skinsort.com',
          'amazon.com',
          'sephora.com',
          'target.com',
          'oliveyoung.co.kr',
          'gmarket.co.kr',
          'coupang.com',
          'naver.com',
          'lotte.com',
          'yesstyle.com',
          'sokoglam.com',
          'stylekorean.com',
          'stylevana.com',
          'beautynetkorea.com',
          'livekbeauty.com',
          'dodoskin.com',
          'koreanbeautycosmetics.com',
        ];
        
        const siteSearchQuery = domains.map(domain => `site:${domain}`).join(' OR ');
        
        let response = await axios.get('https://www.googleapis.com/customsearch/v1', {
          params: {
            key: process.env.REACT_APP_GOOGLE_API_KEY,
            cx: process.env.REACT_APP_GOOGLE_CX,
            q: `${group} (${siteSearchQuery})`,
            gl: language === 'ko' ? 'kr' : 'us',
            hl: language,
            num: 1,
          },
        });

        if (!response.data.items || response.data.items.length === 0) {
          console.log(`No results for "${group}" with siteSearch, retrying without it...`);
          response = await axios.get('https://www.googleapis.com/customsearch/v1', {
            params: {
              key: process.env.REACT_APP_GOOGLE_API_KEY,
              cx: process.env.REACT_APP_GOOGLE_CX,
              q: group,
              gl: language === 'ko' ? 'kr' : 'us',
              hl: language,
              num: 1,
            },
          });
        }
  
        console.log(`Response for group "${group}":`, response.data);
  
        if (response.data && response.data.items) {
          const searchResults = response.data.items.map((item) => ({
            sender: 'bot',
            text: `**${item.title}**\n${item.snippet}\n[Read more](${item.link})`,
          }));
          setMessages((prevMessages) => [...prevMessages, ...searchResults]);
        } else {
          setMessages((prevMessages) => [
            ...prevMessages,
            { sender: 'bot', text: `No search results found for "${group}".` },
          ]);
        }
      } catch (error) {
        console.error(`Error fetching search results for "${group}":`, error);
        setMessages((prevMessages) => [
          ...prevMessages,
          { sender: 'bot', text: `Error fetching search results for "${group}". Please try again later.` },
        ]);
      }
    }
  };

  const renderMessageText = (text) => (
    <ReactMarkdown
      components={{
        a: ({ node, ...props }) => (
          <a {...props} target="_blank" rel="noopener noreferrer">
            {props.children}
          </a>
        ),
      }}
    >
      {text}
    </ReactMarkdown>
  );

  return (
    isLoggedIn ? (
      <div className="chat-container">
        <div className="top-bar" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '10px' }}>
          <div className="build-timestamp" style={{ fontSize: '12px', color: '#888' }}>
            Build: {buildTimestamp}
          </div>

          <div className="language-selector">
            <label htmlFor="language" style={{ color: 'var(--text-color)' }}>
              {language === 'en' ? 'Language:' : '언어:'}
            </label>
            <select
              id="language"
              value={language}
              onChange={handleLanguageChange}
              style={{
                backgroundColor: 'var(--dropdown-bg)',
                color: 'var(--dropdown-text-color)',
              }}
            >
              <option value="en">English</option>
              <option value="ko">한국어</option>
            </select>
          </div>
        </div>

        <div className="chat-box" ref={chatBoxRef}>
          {messages.map((message, index) => (
            <div
              key={index}
              style={{
                display: 'flex',
                justifyContent: message.sender === 'user' ? 'flex-end' : 'flex-start',
                width: '100%',
              }}
            >
              <div className={`message ${message.sender}`}>
                {message.imageUrl ? (
                  <img src={message.imageUrl} alt="Uploaded Preview" className="uploaded-image" />
                ) : (
                  renderMessageText(message.text)
                )}
              </div>
            </div>
          ))}
          {isLoading && (
            <div className="message bot loading">
              <div className="spinner">
                <div className="double-bounce1"></div>
                <div className="double-bounce2"></div>
              </div>
              <span>Typing...</span>
            </div>
          )}
        </div>

        <div className="input-container">
          <input
            type="text"
            value={input}
            onChange={(e) => setInput(e.target.value)}
            placeholder={language === 'en' ? 'Type your message...' : '메시지를 입력하세요...'}
            onKeyPress={(e) => e.key === 'Enter' && handleSend()}
            disabled={isLoading}
          />
          <input
            type="file"
            accept="image/*"
            onChange={handleImageChange}
            style={{ display: 'none' }}
            id="image-upload"
          />
          <label htmlFor="image-upload" className="image-upload-label" title="Upload Image">
            📷
          </label>
          <button onClick={handleSend} disabled={isLoading}>
            {isLoading ? (language === 'en' ? 'Sending...' : '전송 중...') : (language === 'en' ? 'Send' : '보내기')}
          </button>
          <button onClick={handleSearch} className="manual-search-button">
            {language === 'en' ? 'Search' : '검색'}
          </button>
        </div>
      </div>
    ) : (
      <div className="login-container">
        <form onSubmit={handleLogin} className="login-form">
          <h2>Login</h2>
          <div className="input-group">
            <input
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              className="input-field"
            />
          </div>
          <div className="input-group">
            <input
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              className="input-field"
            />
          </div>
          {loginError && <p className="error-message">{loginError}</p>}
          <button type="submit" className="login-button">Login</button>
          <div className="oauth-section">
            <button type="button" onClick={handleGoogleSignIn} className="oauth-button">
              Sign in with Google
            </button>
          </div>
        </form>
      </div>
    )
  );
}

export default App;
